import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="job-application"
export default class extends Controller {
  static targets = ["form", "alert", "applicationForm", "otherInput", "otherField", "position", "wrapper", "description"];
  static values = { type: String }

  connect() {
    console.log("form render connected");
  }

  formRender(e) {
    const url = `/build_form?selection=${e.target.value}`;
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        'X-CSRF-Token': csrf
      }
    })
    .then(res => res.json())
    .then(data => {
      this.formTarget.innerHTML = data.html
    })
  }

  submit(e) {
    e.preventDefault()
    console.log('submitting form...')
    this.alertTarget.innerHTML = ""
    const url = this.applicationFormTarget.action
    fetch(url, {
      method: "POST",
      headers: { "Accept": "text/plain" },
      body: new FormData(this.applicationFormTarget)
    })
      .then(response => response.text())
      .then((data) => {
        this.applicationFormTarget.outerHTML = data
      })
  }

  toggleOtherInput(e) {
    if (e.target.value === 'Other') {
      this.otherFieldTarget.classList.remove('d-none');
    } else {
      this.otherFieldTarget.classList.add('d-none');
    }
  }

  descRender(e) {
    const url = `/render_desc?selection=${e.target.value}`;
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        'X-CSRF-Token': csrf
      }
    })
    .then(res => res.json())
    .then(data => {
      this.descriptionTarget.innerHTML = data.html
    })

    this.positionTarget.value = `${e.target.value} Intern`

    this.wrapperTarget.classList.remove("d-none")
    // this.descriptionTarget.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
}
