import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll-top"
export default class extends Controller {
  static targets = ["button"]

  connect() {
    console.log("Scroll top controller connected");
  }

  showButton() {
    if (window.scrollY >= 120) {
      // this.buttonTarget.style.display = "block";
      this.buttonTarget.classList.add("visible");
    } else {
      this.buttonTarget.classList.remove("visible");
      // this.buttonTarget.style.display = "none";
    }
  }

  scrollToTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
}
